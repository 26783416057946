// src/components/AboutPage.js
import React from 'react';
import '../styles/AboutPage.css';

const AboutPage = () => {
  return (
    <div className="about">
      <h1>About Us</h1>
      <p>Welcome to Harmony Space! We are committed to delivering exceptional service tailored to your needs. Our dedicated team strives to not only meet but exceed your expectations. We offer comprehensive architectural and interior design services throughout the Greater Seattle Area. Our expertise includes site analysis, new construction design, addition design, remodel design, functional resolution, permit drawings, and permit applications.</p>
    </div>
  );
};

export default AboutPage;
