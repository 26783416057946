// src/data/projectList.js
const projectList = [
  'ADU Design',
  'Architectural Concept Design',
  'Architectural and Landscape Design',
  'Backyard ADU',
  'Bathroom Design',
  'Bathroom Remodel',
  'Bellevue Addition and Renovation Exterior',
  'Bellevue Addition and Renovation Interior',
  'Bellevue New Construction Exterior',
  'Bellevue New Construction Interior',
  'Interior Concept Design',
  'Kirkland Addition',
  'Monastery Concept Design',
  'New Construction Design',
  'Office Design',
  'Pavilion Concept Design',
  'Redmond Renovation',
  'Remodel Design',
  'Remodel Interior Design',
  'Resort House Design',
  'Santa Clara Renovation',
  // Add more projects as needed
];

export default projectList;
