// src/components/ContactPage.js
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import '../styles/ContactPage.css';

const ContactPage = () => {
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your email sending logic here. This could be an API call to your backend or a third-party email service
    
    emailjs
      .sendForm('service_m0n2epl', 'template_vhhyhb9', form.current, {
        publicKey: 'RlFRcrOeUk8wk25oj',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );

    e.target.reset();
    alert('Message sent!');
  };

  return (
    <div className="contact">
      <h1>Contact Us</h1>
      <p>Email: harmonyspacellc@outlook.com</p>
      <form ref={form} onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" name="name" value={form.name} required />
        </label>
        <label>
          Email:
          <input type="email" name="email" value={form.email} required />
        </label>
        <label>
          Phone:
          <input type="tel" name="phone" value={form.phone} required />
        </label>
        <label>
          Subject:
          <input type="text" name="subject" value={form.subject} required />
        </label>
        <label>
          Message:
          <textarea name="message" value={form.message} required />
        </label>
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default ContactPage;
