// src/components/ProjectPage.js
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import imagePaths from './imagePaths';
import projectList from '../data/projectList'; // Import the project list
import '../styles/ProjectPage.css'; // Create this stylesheet

// Function to dynamically import all images from a specific project folder
function importAll(r) {
  return r.keys().map((key) => ({
    src: r(key),
    name: key.substring(2, key.lastIndexOf('.')), // Remove './' and file extension
  }));
}

const ProjectPage = () => {
  const { projectName } = useParams();

  // Import images from the specific project folder
  let images = [];
  if (imagePaths[projectName]) {
    try {
      images = importAll(imagePaths[projectName]);
    } catch (err) {
      console.error(`Error loading images for project ${projectName}:`, err);
    }
  } else {
    console.error(`No image path found for project ${projectName}`);
  }

  // Find the current project's index in the project list
  const currentIndex = projectList.indexOf(projectName);
  const previousProject = currentIndex > 0 ? projectList[currentIndex - 1] : null;
  const nextProject = currentIndex < projectList.length - 1 ? projectList[currentIndex + 1] : null;

  return (
    <div className="project-page">
      <h2>{projectName} Project</h2>
      <div className="project-images">
        {images.length > 0 ? (
          images.map((image, index) => (
            <img key={index} src={image.src} alt={`img-${index}`} className="project-image" />
          ))
        ) : (
          <p>No images found for this project.</p>
        )}
      </div>

      <div className="navigation-links">
        {previousProject && (
          <Link to={`/projects/${previousProject}`} className="nav-link">
            Previous Project
          </Link>
        )}
        {nextProject && (
          <Link to={`/projects/${nextProject}`} className="nav-link">
            Next Project
          </Link>
        )}
      </div>
      
    </div>
  );
};

export default ProjectPage;
