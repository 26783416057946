// src/components/imagePaths.js
const imagePaths = {
  "Remodel Interior Design": require.context('../images/projects/Remodel Interior Design', false, /\.(png|jpe?g|svg)$/),
  "New Construction Design": require.context('../images/projects/New Construction Design', false, /\.(png|jpe?g|svg)$/),
  "Bathroom Design": require.context('../images/projects/Bathroom Design', false, /\.(png|jpe?g|svg)$/),
  "Architectural and Landscape Design": require.context('../images/projects/Architectural and Landscape Design', false, /\.(png|jpe?g|svg)$/),
  "Remodel Design": require.context('../images/projects/Remodel Design', false, /\.(png|jpe?g|svg)$/),
  
  "Architectural Concept Design": require.context('../images/projects/Architectural Concept Design', false, /\.(png|jpe?g|svg)$/),
  "Resort House Design": require.context('../images/projects/Resort House Design', false, /\.(png|jpe?g|svg)$/),
  "Interior Concept Design": require.context('../images/projects/Interior Concept Design', false, /\.(png|jpe?g|svg)$/),
  "Office Design": require.context('../images/projects/Office Design', false, /\.(png|jpe?g|svg)$/),
  "Bathroom Remodel": require.context('../images/projects/Bathroom Remodel', false, /\.(png|jpe?g|svg)$/),
  
  "Bellevue New Construction Exterior": require.context('../images/projects/Bellevue New Construction Exterior', false, /\.(png|jpe?g|svg)$/),
  "Bellevue New Construction Interior": require.context('../images/projects/Bellevue New Construction Interior', false, /\.(png|jpe?g|svg)$/),
  "Bellevue Addition and Renovation Exterior": require.context('../images/projects/Bellevue Addition and Renovation Exterior', false, /\.(png|jpe?g|svg)$/),
  "Bellevue Addition and Renovation Interior": require.context('../images/projects/Bellevue Addition and Renovation Interior', false, /\.(png|jpe?g|svg)$/),
  "Kirkland Addition": require.context('../images/projects/Kirkland Addition', false, /\.(png|jpe?g|svg)$/),
  
  "Santa Clara Renovation": require.context('../images/projects/Santa Clara Renovation', false, /\.(png|jpe?g|svg)$/),
  "Redmond Renovation": require.context('../images/projects/Redmond Renovation', false, /\.(png|jpe?g|svg)$/),
  "Monastery Concept Design": require.context('../images/projects/Monastery Concept Design', false, /\.(png|jpe?g|svg)$/),

  "Backyard ADU": require.context('../images/projects/Backyard ADU', false, /\.(png|jpe?g|svg)$/),
  "ADU Design": require.context('../images/projects/ADU Design', false, /\.(png|jpe?g|svg)$/),
  "Pavilion Concept Design": require.context('../images/projects/Pavilion Concept Design', false, /\.(png|jpe?g|svg)$/)

  // Add more projects here
};

export default imagePaths;
