// src/components/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/HomePage.css';

// Function to dynamically import all images from the 'src/images/homepage/' folder
function importAll(r) {
  return r.keys().map((key) => ({
    src: r(key),
    name: key.substring(2, key.lastIndexOf('.')), // Remove './' and file extension
  }));
}

// Import all images from the 'src/images/homepage' folder
const images = importAll(require.context('../images/homepage', false, /\.(png|jpe?g|svg)$/));

const HomePage = () => {
  return (
    <div className="image-grid-container">
      <div className="slogan">
        <h1 >Architectural & Interior Design</h1>
        <p>nature and heart in harmony</p>
      </div>
      
      <div className="image-grid">
        {images.map((image, index) => (
          <div key={index} className="image-container">
            <Link to={`/projects/${image.name}`}>
              <img src={image.src} alt={`img-${index}`} className="image-item" />
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};


export default HomePage;

