// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import ProjectPage from './components/ProjectPage'; // Create this component for displaying project images
import ContactPage from './components/ContactPage';
import AboutPage from './components/AboutPage';
import NotFoundPage from './components/NotFoundPage';
import './styles/App.css';


const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/projects/:projectName" element={<ProjectPage />} />
          <Route path="/projects" element={<Navigate to="/" replace />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
};


export default App;
